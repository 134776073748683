<template>
  <v-row align='center' justify='center' class='text-center'>
    <v-btn class='ml-4'
      ><download-excel
        class='btn btn-default'
        :data='list1'
        :fields='json_fields'
        worksheet='My Worksheet'
        name='ข้อมูลสรุปรายงานการประวัติเเจ้งเหตุ.xls'
        header='รายงานประวัติการเเจ้งเหตุ'
      >
        <img src='@/assets/Excel-Logo.png' width='50px' />
        Download รายงานประวัติการเเจ้งเหตุ
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ['list1'],
  data() {
    return {
      json_fields: {
        'หมายเลขการแจ้งเหตุ': 'requestNumber',
        'วัน เวลา ที่แจ้งเหตุ': 'requestDate',
        'วัน เวลา ที่เจ้าหน้าที่รับเริ่อง': 'updatedAt',
        'ประเภทการแจ้งเหตุ': 'typeName',
        'จังหวัด': 'requestProvince',
        'อำเภอ': 'requestDistrict',
        'ตำบล': 'requestSubDistrict',
        'ชื่อผู้แจ้งเหตุ': 'userReqName',
        'อีเมลล์ผู้แจ้งเหตุ': 'userReqEmail',
        'เบอร์โทรศัพท์ผู้แจ้งเหตุ': 'userReqTel',
        'รายละเอียดการแจ้งเหตุ': 'description'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      list1: []
    }
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log('data', response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  }
}
</script>
